<template>
    <main class="PopUpBg-MAIN">

        <div class="PopUp-content">
            <div class="PopUp-close-btn" @click="closePopUp()" v-if="popUpMode != 'PagoExitoso' && popUpMode != 'PagoError'">+</div>
            <PopUpRestricciones v-if="popUpMode == 'restricciones'"/>
            <TuViaje v-if="popUpMode == 'TuViaje'"/>
            <!--<LoginRegister v-if="popUpMode == 'LoginRegister'"/>-->
            <DatosBancarios v-if="popUpMode == 'DatosBancarios'"/>
            <AgendarLlamada v-if="popUpMode == 'AgendarLlamada'"/>
            <ProcesoExitoso v-if="popUpMode == 'RegistroExitoso'" :type="popUpMode" :title="'¡Registro Exitoso!'" :text="'Recibimos tu información, en breve un asesor de contactará o puedes mandar WA a:'"/>
            <ProcesoExitoso v-if="popUpMode == 'PagoExitoso'" :type="popUpMode" :title="'¡Pago Exitoso!'" :text="'Consulta la información de tu reservación enviada a tu correo.'"/>
            <ProcesoError v-if="popUpMode == 'RegistroError'" :type="popUpMode" :title="'¡Hubo un error!'" :text="'Verifica que los datos sean los correctos.'"/>
            <ProcesoError v-if="popUpMode == 'PagoError'" :type="popUpMode" :title="'¡Hubo un error en tu pago!'" :text="'Verifica que los datos de tu tarjeta sean los correctos.'"/>
            <IngresaTusDatos v-if="popUpMode == 'IngresaTusDatos'"/>
            <NotAvailable v-if="popUpMode == 'notAvailable'"/>
        </div>

    </main>
</template>

<script>
import AgendarLlamada from './AgendarLlamada.vue';
import DatosBancarios from './DatosBancarios.vue';
//import LoginRegister from './LoginRegister.vue';
import PopUpRestricciones from './PopUpRestricciones.vue';
import ProcesoExitoso from './ProcesoExitoso.vue';
import ProcesoError from './ProcesoError.vue';
import TuViaje from './TuViaje.vue';
import IngresaTusDatos from './IngresaTusDatos.vue';
import NotAvailable from './NotAvailable.vue';
export default {
    components: { PopUpRestricciones, TuViaje, /*LoginRegister,*/ DatosBancarios, AgendarLlamada , ProcesoExitoso, ProcesoError, IngresaTusDatos, NotAvailable},
    name: 'PopUpBg',
    props:{
        popUpMode: String,
    },
    methods:{
        closePopUp(){
            this.$parent.closePopUp();
            //console.log("Delete product data");
            //alert("closing pop up")
            //localStorage.removeItem("productData");
            const url = window.location.href;
            if(url.includes("PagoExitoso") || url.includes("PagoError")){
                window.location.href = "https://www.sand-diamante.mx/landing_renta/";
            }
        },

        launchPopUp(mode_){
            this.$parent.launchPopUp(mode_);
        }
    }
}
</script>

<style>
.PopUpBg-MAIN{
    background-color: #152f62d2;
    width: 100%;
    min-height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: 'Montserrat', sans-serif;
}

.PopUp-content{
    padding: 4ch;
    padding-top: 2ch;
    background-color: white;
    position: relative;
    border-radius: 1ch;
    min-width: 35vw;
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.PopUp-close-btn{
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 4ch;
    height: 1ch;
    width: 1ch;
    top: 0.5ch;
    right: 0.5ch;
    color: #979797;
    transform: rotate(45deg);
}

.PopUp-close-btn:hover{
    cursor: pointer;
}

.PopUp-title{
    color: #1D7ABE;
    display: flex;
    align-items: center;
}

.PopUp-title img{
    max-height: 1.5ch;
    margin-right: 0.5ch;
}

.PopUp-btn{
    background-color: #1D7ABE;
    color: white;
    height: 5ch;
    border: none;
    padding: 0 2ch;
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
    font-weight: 600;
    min-width: 14ch;
}
</style>