<template>
    <main class="AvisoPrivacidad-MAIN">

        <img src="../assets/images/consentimiento-tratamiento-datos.png">

        <article>
            Consentimiento de datos personales en posesión de Inmobiliaria Gabriel Mancera 1, S.A de C.V, y/o sus empresas filiales también conocidas como THE ONE INMOBILIARIA con domicilio en Avenida Año de Juárez 223, Granjas San Antonio, Alcaldía Iztapalapa, C.P. 09070, en la Ciudad de México y con fundamento en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares y del Reglamento de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (la “Ley”), requerimos de usted su consentimiento expreso y por escrito para el tratamiento de sus datos personales mismo que al suscribir algún contrato y/o al ofrecer al “Responsable celebrar el mismo mediante carta oferta o cualquier otro documento, acepta los términos y condiciones del presente Aviso, ya que ha sido informado de las condiciones sobre la protección de datos personales y del mecanismo para el acceso, rectificación, cancelación u oposición, de conformidad con lo dispuesto en la “Ley”, manifestando expresamente su aceptación y consentimiento al tratamiento de los mismos por parte del “Responsable” en la forma y para las finalidades indicadas en el presente Aviso de Privacidad.
            <br><br>
            La sociedad recabará de usted los datos personales que sean necesarios para la adecuada Prestación de servicios profesionales de promoción, venta y arrendamiento de bienes inmuebles ya sea directa o indirectamente a través de sus agentes, promotores, comisionistas o socios comerciales, así como para la elaboración de contratos de compra venta y/o renta de bienes inmuebles. 
            <br><br>
            Dichos Datos Personales podrán incluir todos o algunos de los siguientes:
            <br><br>
            <b>a.</b> Datos de identificación: nombre completo, dirección, télefono de casa celular y/o de trabajo, estado civil, nombre del cónyuge, concubinario (a) o pareja en convivencia, en su caso, firma, firma electrónica, Registro Federal de Contribuyentes (RFC). Clave Unica de Registro Poblacional (CURP), lugar y fecha de nacimiento, edad, nombres de familiares (padres y hermanos), dependientes económicos y beneficiarios.
            <br><b>b.</b> Datos laborales: Datos laborales: ocupación, nombre de la empresa o dependencia, puesto, área o departamento, domicilio, teléfono y correo electrónico de trabajo, actividades extracurriculares, referencias laborales y referencias personales.
            <br><b>c.</b> Datos Patrimoniales: bienes muebles e inmuebles, historial crediticio, ingresos y egresos, cuentas bancarias, seguros, afore, fianzas, cualquier tipo de garantía otorgada, y servicios contratados. La sociedad no recabará ni tratará datos personales sensibles. 
            <br><br>
            Los datos personales proporcionados se integrarán a las bases de datos con los que contamos, con la finalidad de registrar, mantener y administrar la relación derivada de los contratos de compraventa y/o cartas oferta que se celebren entre el “Responsable” y Consentimiento para el tratamiento de Datos Personales los “Titulares” y /o para llevar a cabo la debida administración de sus expedientes por el “Responsable”.
            <br><br>
            Los datos personales que nos proporcione, y la documentación respectiva, serán recabados a través de usted y/o a través de sus representantes legales, apoderados, factores, dependientes, funcionarios o empleados. 
            <br><br>
            Asimismo, se hace de su conocimiento que el “Responsable” realizará el tratamiento de los datos personales, única y exclusivamente para los siguientes efectos: I) la compraventa y/o arrendamiento que haya celebrado o se pretenda celebrar y para dar cumplimiento a todas las obligaciones a cargo del “Responsable” derivadas y/o relacionados con el contrato de arrendamiento, compraventa y/o carta oferta, según sea el caso, II) para documentar y formalizar la relación relacionada con dicho contrato y / o carta oferta, incluyendo su correspondiente protocolización ante Notario Público y posterior inscripción ante el Registro Público de la Propiedad que corresponda, III) para que el “Titular” obtenga en caso de requerirlo de alguna institución bancaria, crediticia o de cualquier tercero un crédito a fin de encontrarse en aptitud de realizar el pago de las contraprestaciones que correspondan en términos del contrato de compraventa y/o carta oferta, que unen al “Responsable” con el “Titular”, IV) para fines estadísticos, incluyendo de forma enunciativa más no limitativa la apertura de los expedientes correspondientes, alta y modificación en los sistemas del “Responsable”, V) facturación de la contraprestación a que tenga derecho el ‘Responsable”, VI) presentación de las declaraciones e informes que sean requeridas en términos de ley ante las Autoridades correspondientes, VII) elaboración de los documentos correspondientes de conformidad con lo solicitado por los “Titulares” y VIII) la relación derivada del contrato de compraventa y/o carta oferta, siendo que el “Responsable” ha instrumentado todas las medidas de seguridad, administrativas, técnicas y físicas para proteger contra daño, pérdida, alteración, destrucción o uso, acceso o tratamiento no autorizado de la información proporcionada. 
            <br><br>
            Recibí el presente Aviso de Privacidad, el cual consta de 1 (1) hoja, contenido que se encuentra solo por el anverso, por lo que soy sabedor de su contenido y manifiesto que me encuentro de acuerdo con el mismo. De igual forma, manifiesto que soy sabedor de que dicho Aviso de Privacidad de se encuentra publicado en la página de internet del “Titular” www.theoneinmobiliaria.mx, por lo que todas las actualizaciones del presente Aviso de Privacidad se encontrarán a mi disposición a través de dicho medio.
        </article>

    </main>
</template>

<script>
export default {
    name: 'AvisoPrivacidad',
}
</script>

<style>
.AvisoPrivacidad-MAIN{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.AvisoPrivacidad-MAIN > img{
    width: 50ch;
    max-width: 85vw;
    margin-top: 4ch;
}

.AvisoPrivacidad-MAIN > article{
    color: #4B8BBC;
    width: 75vw;
    margin: 4ch 0;
    font-family: 'Montserrat', sans-serif;
}

/********************************************************************
 * RESPONSIVE
 ********************************************************************/
@media all and (max-width:780px){

    .AvisoPrivacidad-MAIN > article, .AvisoPrivacidad-MAIN > img{
        width: 90vw;
        max-width: unset;
    }

}
</style>