<template>
    <nav>
  
        <div class="INNER-MARGIN">
            <img src="../assets/images/sand-logo.svg" @click="reload()">

            <div class="contactanos">
                <img src="../assets/images/user.svg">
            </div>
        </div>

    </nav>
</template>
  
<script>
export default {
  name: 'NavOneLife',
  methods:{
    reload(){
        this.$parent.currentView = 'all';
        window.location.reload();
    }
  }
}
</script>
  
<style scoped>
nav{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #167AC0;
    background-color: #202945;
    background-color: white;
    z-index: 20;
    font-family: 'Montserrat', sans-serif;
    box-shadow: 0px 0px 2ch rgba(100, 100, 100, 0.2);
}

nav .INNER-MARGIN, 
nav .INNER-MARGIN > div{
    display: inherit;
    align-items: inherit;
    justify-content: space-between;
}

nav .INNER-MARGIN > img{
    height: 50px;
    cursor: pointer;
    /*filter: brightness(0) invert(1);*/
}

nav .INNER-MARGIN > div > img{
    height: 30px;
}

.contactanos:hover{
    cursor: pointer;
}
</style>