<template>
    <div class="IngresaTusDatos-MAIN">

        <img src="../../assets/images/sand-logo.svg">

        <p>Ingresa tus datos para continuar</p>

        <div><div><img src="../../assets/images/user-blue.svg"></div><input type="text" ref="registerName" placeholder="Nombre*"></div>
        <div><div><img src="../../assets/images/white-px.png"></div><input type="text" ref="registerLastname" placeholder="Apellidos*"></div>
        <div><div><img src="../../assets/images/envelope-blue.svg"></div><input type="email" ref="registerEmail" placeholder="Email*"></div>
        <div><div><img src="../../assets/images/mobile-notch.svg"></div><input type="number" ref="registerPhone" placeholder="Teléfono celular*" maxlength="10" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"></div>

        <button @click="register()" ref="btn">Registrarme</button>

    </div>
</template>

<script>
export default {
    name: 'IngresaTusDatos',
    methods:{
        register(){
            const username = this.$refs.registerName;
            const lastname = this.$refs.registerLastname;
            const email = this.$refs.registerEmail;
            const tel = this.$refs.registerPhone;

            const btn = this.$refs.btn;
            btn.style.opacity = 0.5;
            btn.style.pointerEvents = "none";

            if (!username.value) {
                username.focus();
                return;
            }
            if (!lastname.value) {
                lastname.focus();
                return;
            }
            if (!email.value || !this.validateEmail(email.value)) {
                email.focus();
                return;
            }
            if (!tel.value || !this.validatePhoneNumber(tel.value)) {
                tel.focus();
                return;
            }

            fetch('https://admin.sand-diamante.mx/api/registro',
                {
                    method: 'POST',
                    body: JSON.stringify({
                        nombre: username.value,
                        apellidos: lastname.value,
                        email: email.value,
                        telefono: tel.value
                    }),
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*'
                    }
                })
                .then(response => response.json())
                .then(json => {
                    console.log(json)
                    if(json.error == 0){
                        localStorage.setItem('retrievedUserData', JSON.stringify(json.usuario));
                        //this.createPreference();
                    }
                });

            document.getElementById("conektaIframeContainer").style.display = "flex";
        },

        validateEmail(email) {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(email);
        },

        validatePhoneNumber(phoneNumber) {
            const phoneRegex = /^\d{8,10}$/;
            return phoneRegex.test(phoneNumber);
        },

    }

}
</script>

<style scoped>
.IngresaTusDatos-MAIN{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.IngresaTusDatos-MAIN > img{
    width: 18ch;
    margin-top: 2ch;
}

.IngresaTusDatos-MAIN > p{
    margin-bottom: 3ch;
}

.IngresaTusDatos-MAIN div{
    display: flex;
    align-items: center;
    margin-bottom: 1.5ch;
}

.IngresaTusDatos-MAIN div > div{
    width: 2.5ch;
    aspect-ratio: 1/1 !important;
    margin: 0;
    margin-right: 1ch;
    display: flex;
    align-items: center;
    justify-content: center;
}

input{
    padding: 0.8ch 1.8ch;
    border: 1px solid gray;
    border-radius: 5px;
}

input::placeholder{
    font-family: 'Montserrat', sans-serif;
    color: gray;
}

button{
    height: 5ch;
    border: none;
    padding: 0 1ch;
    background-color: #167AC0;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    color: white;
    width: 15ch;
    min-width: 40%;
    text-transform: uppercase;
    margin-top: 3ch;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}
</style>