<template>
  <main class="TheOneLife-MAIN">

    <div class="conekta-iframe" id="conektaIframeContainer" style="height: 1350px;" data-aos="zoom-in">
      <button @click="closeIframe()">Cancelar</button>
    </div>
    <div id="loader"><img src="../assets/images/load.gif"></div>

    <NavOneLife data-aos="fade-down"/>
    <div class="landing">
      <CoverSearch v-if="!searchMode && currentView == 'all'" :searchData="searchData"/>
      <EspacioIdeal v-if="!searchMode && currentView == 'all'"/>
      <ReservaEstancia v-if="!searchMode && currentView == 'all'"/>
      <SearchActive v-if="searchMode" :searchData="searchData"/>
      <FilteredSearch v-if="searchMode" :filteredProducts="filteredProducts"/>
      <PopUpBg v-if="popUpMode != false" :popUpMode="popUpMode"/>
      <AvisoPrivacidad v-if="currentView == 'avisoPrivacidad'"/>
      <EndPage :currentView="currentView"/>
    </div>

    <div class="whatsapp-btn">
      <a href="https://api.whatsapp.com/send?phone=5632432372" target="blank_">
        <img src="../assets/images/whatsapp-btn.svg">
      </a>
    </div>

  </main>
</template>

<script>
import CoverSearch from "./CoverSearch.vue";
import EndPage from "./EndPage.vue";
import EspacioIdeal from "./EspacioIdeal.vue";
import FilteredSearch from "./FilteredSearch.vue";
import NavOneLife from "./NavOneLife.vue";
import PopUpBg from "./PopUps/PopUpBg.vue";
import ReservaEstancia from "./ReservaEstancia.vue";
import SearchActive from "./SearchActive.vue";
import AvisoPrivacidad from "./AvisoPrivacidad.vue";
import AOS from 'aos';
import 'aos/dist/aos.css';

export default {
  name: "TheOneLife",
  components: {
    NavOneLife,
    CoverSearch,
    EndPage,
    EspacioIdeal,
    ReservaEstancia,
    SearchActive,
    FilteredSearch,
    PopUpBg,
    AvisoPrivacidad,
  },
  data() {
    return {
      searchMode: false,
      searchData: {
        rentTime: "Fin de semana",
        arrival: "",
        exit: "",
        guests: 1,
      },
      popUpMode: false, // false.
      allProducts: [
        {
          id: 1,
          supportedTime: ["Fin de semana", "Semana"],
          img: [
            //require("../assets/images/item-dep-equipado-lateral.png"),
            require("../assets/images/lat0.png"),
            require("../assets/images/lat1.png"),
            require("../assets/images/lat2.png"),
            require("../assets/images/lat3.png"),
            require("../assets/images/lat4.png"),
          ],
          name: "Departamento equipado lateral",
          items: {
            meters: 104,
            rooms: 2,
            terrazas: 2,
            bathrooms: 2,
            estacionamiento: 1,
          },
          price: "3,300", //"3,300",
          priceMode: "MXN noche",
          subPrice: false,
          discount: false,
          amenities: ["2 Habitaciones", "2 camas matrimoniales", "2 camas individuales", "Albercas", "Área de camastros y asoleaderos húmedos.", "Snack bar", "Área de jardines", "Pet park", "Área de bicicletas", "Wifi en áreas comunes", "Motor Lobby", "Sala de reuniones", "Gimnasio", "Roof Garden", "Roof bar", "Estacionamiento", "Vigilancia y seguridad", "Club de playa"]
        },
        {
          id: 2,
          supportedTime: ["Fin de semana", "Semana"],
          img: [
            //require("../assets/images/item-dep-equipado-central.png"),
            require("../assets/images/cen0.png"),
            require("../assets/images/cen1.png"),
            require("../assets/images/cen2.png"),
            require("../assets/images/cen3.png"),
            require("../assets/images/cen4.png"),
          ],
          name: "Departamento equipado central",
          items: {
            meters: 122,
            rooms: 2,
            terrazas: 3,
            bathrooms: 2,
            estacionamiento: 1,
          },
          price: "3,600", //"3,600",
          priceMode: "MXN noche",
          subPrice: false,
          discount: false, //10
          amenities: ["2 Habitaciones", "2 camas matrimoniales", "2 camas individuales", "Albercas", "Área de camastros y asoleaderos húmedos.", "Snack bar", "Área de jardines", "Pet park", "Área de bicicletas", "Wifi en áreas comunes", "Motor Lobby", "Sala de reuniones", "Gimnasio", "Roof Garden", "Roof bar", "Estacionamiento", "Vigilancia y seguridad", "Club de playa"]
        },
        {
          id: 3,
          supportedTime: ["Mes", "Año"],
          img: [require("../assets/images/item-dep-equipado-lateral.png")],
          name: "Departamento equipado lateral",
          items: {
            meters: 105.75,
            rooms: 2,
            terrazas: 2,
            bathrooms: 2,
            estacionamiento: 1,
          },
          price: "15,500",
          priceMode: "Mensual",
          subPrice: false,
          discount: false,
          amenities: ["2 Habitaciones", "2 camas matrimoniales", "2 camas individuales", "Albercas", "Área de camastros y asoleaderos húmedos.", "Snack bar", "Área de jardines", "Pet park", "Área de bicicletas", "Wifi en áreas comunes", "Motor Lobby", "Sala de reuniones", "Gimnasio", "Roof Garden", "Roof bar", "Estacionamiento", "Vigilancia y seguridad", "Club de playa"]
        },
        {
          id: 4,
          supportedTime: ["Mes", "Año"],
          img: [require("../assets/images/item-dep-equipado-central.png")],
          name: "Departamento equipado central",
          items: {
            meters: 118.5,
            rooms: 2,
            terrazas: 3,
            bathrooms: 2,
            estacionamiento: 1,
          },
          price: "17,000",
          priceMode: "Mensual",
          subPrice: false,
          discount: false,
          amenities: ["2 Habitaciones", "2 camas matrimoniales", "2 camas individuales", "Albercas", "Área de camastros y asoleaderos húmedos.", "Snack bar", "Área de jardines", "Pet park", "Área de bicicletas", "Wifi en áreas comunes", "Motor Lobby", "Sala de reuniones", "Gimnasio", "Roof Garden", "Roof bar", "Estacionamiento", "Vigilancia y seguridad", "Club de playa"]
        },
        {
          id: 5,
          supportedTime: ["Mes", "Año"],
          img: [require("../assets/images/item-pen-equipamiento-lateral.png")],
          name: "Penthouse equipamiento lateral",
          items: {
            meters: 233,
            rooms: 3,
            terrazas: 3,
            bathrooms: "3½",
            estacionamiento: 1,
          },
          price: "34,000",
          priceMode: "Mensual",
          subPrice: false,
          discount: false,
          amenities: ["3 Habitaciones", "1 cama matrimonial por habitación", "Albercas", "Área de camastros y asoleaderos húmedos.", "Snack bar", "Área de jardines", "Pet park", "Área de bicicletas", "Wifi en áreas comunes", "Motor Lobby", "Sala de reuniones", "Gimnasio", "Roof Garden", "Roof bar", "Estacionamiento", "Vigilancia y seguridad", "Club de playa"]
        },
        {
          id: 6,
          supportedTime: ["Mes", "Año"],
          img: [require("../assets/images/item-pen-equipamiento-central.png")],
          name: "Penthouse equipamiento central",
          items: {
            meters: 240,
            rooms: 3,
            terrazas: 3,
            bathrooms: "3½",
            estacionamiento: 1,
          },
          price: "34,000",
          priceMode: "Mensual",
          subPrice: false,
          discount: false,
          amenities: ["3 Habitaciones", "1 cama matrimonial por habitación", "Albercas", "Área de camastros y asoleaderos húmedos.", "Snack bar", "Área de jardines", "Pet park", "Área de bicicletas", "Wifi en áreas comunes", "Motor Lobby", "Sala de reuniones", "Gimnasio", "Roof Garden", "Roof bar", "Estacionamiento", "Vigilancia y seguridad", "Club de playa"]
        },
      ],
      filteredProducts: [],
      currentView: "all", // 'all' | 'avisoPrivacidad' | 'reglamentoConvivencia'
    };
  },
  methods: {
    setSearchData(data_) {
      this.searchData = data_;
      console.log("searchData", this.searchData);
      if(this.searchData.arrival == "" || this.searchData.exit == ""){
        return;
      }
      this.filteredProducts = [];
      // Search:
      for (let product of this.allProducts) {
        if (product.supportedTime.includes(this.searchData.rentTime)) {
          this.filteredProducts.push(product);
        }
      }

      /*fetch('https://admin.sand-diamante.mx/api/vigencia', {
        method: 'POST',
        body: JSON.stringify({
          "fecha_inicial": this.searchData.arrival,
          "fecha_final": this.searchData.exit,
          "id": 1
        }),
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        }
      })
      .then(response => response.json())
      .then(json => {
        console.log(json)
        this.searchMode = true;
      });*/
      // End search.
      localStorage.setItem("searchData", JSON.stringify(this.searchData));
      this.searchMode = true;
    },

    closePopUp() {
      this.popUpMode = false;
      setTimeout(() => {
        this.popUpMode = false;
      }, 80);
      console.log("closing")
    },

    launchPopUp(mode_) {
      if(!Array.isArray(mode_)){
        this.popUpMode = mode_;
        return;
      }

      if (mode_.includes("Fin de semana") || mode_.includes("Semana")) {
        this.popUpMode = "restricciones";
      } else {
        this.popUpMode = "AgendarLlamada";
      }

    },

    readURLparams(){
      const urlParams = new URLSearchParams(window.location.search);
      const msg = urlParams.get('msg');
      console.log("MSG:", msg);

      if(msg == "RegistroExitoso" || msg == "registroexitoso"){
        this.launchPopUp("RegistroExitoso");
      }else
      if(msg == "PagoExitoso" || msg == "pagoexitoso"){
        this.launchPopUp("PagoExitoso");
      }
      if(msg == "RegistroError" || msg == "registroerror"){
        this.launchPopUp("RegistroError");
      }else
      if(msg == "PagoError" || msg == "pagoerror"){
        this.launchPopUp("PagoError");
      }
    },

    closeIframe(){
      document.getElementById("conektaIframeContainer").style.display = "none";
      //let iframe = document.querySelector(".zoid-visible");
      // eslint-disable-next-line
      //iframe.src = iframe.src;
    }
  },

  mounted(){
    this.readURLparams();
    AOS.init();
  }
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap");

.TheOneLife-MAIN{
  max-width: 100vw;
  overflow-x: hidden;
  overflow-y: hidden;
}

*{
  scroll-behavior: smooth;
}

.INNER-MARGIN {
  width: 900px;
  width: 1080px;
  max-width: 90vw;
  /*border: 1px dotted magenta;*/
}

.landing {
  margin-top: 75px;
}

.slider {
  width: 100%;
  height: 50vh;
}

button:hover {
  cursor: pointer;
  transform: scale(1.02);
  transition: all 0.2s;
}

button:active {
  transform: scale(0.98);
  transition: all 0.1s;
}

.whatsapp-btn{
  position: fixed;
  bottom: 0ch;
  right: 0ch;
  margin: 2ch;
  width: 8ch;
  aspect-ratio: 1/1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 100vw;
  overflow: hidden;
}

.whatsapp-btn:hover{
  scale: 1.05;
}

.whatsapp-btn a img{
  width: 100%;
  scale: 1.4;
}

.conekta-iframe{
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 200;
  background-color: rgba(0,0,0,0.5);
  display: flex;
  display: none;
  flex-direction: column;
  align-items: center;
}

.conekta-iframe > button{
  position: absolute;
  margin: auto;
  top: 90vh;
  z-index: 2;
  color: white;
  background-color: #167AC0;
  padding: 1ch 2ch;
  border: none;
  border-radius: 5px;
  font-family: 'Montserrat', sans-serif;
  font-weight: bolder;
  font-size: 2.2ch;
  width: 470px;
  max-width: 83vw;
}

#loader{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  background-color: rgba(0,0,0,0.5);
  display: none;
}

#loader img{
  width: 10ch;
}
</style>